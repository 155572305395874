import dayjs from 'dayjs';
import { formattedCardNumber } from 'lib/utils';
import useTranslate from 'lib/languages/useTranslate';
import Typography from 'components/shared/generic/typography/presentational/Typography';

const MigratedCard = ({ position,info, isDarkText }) => {
    const cardNumber = formattedCardNumber(info.cardPan);
    const translate = useTranslate('cards.cardSlider');
    
    return (
        <div className={`content frozen-card ${isDarkText ? 'dark-text' : ''}`}>
            <Typography className="expiry-date">
                {dayjs(info.expiryDate).format('YY/MM')}
            </Typography>
            <Typography tag='span' className="card-number">{cardNumber}</Typography>
            <Typography className="name">{info.cardHolderName}</Typography>
            {position === 'center' && (
                <Typography className='card-migration-text'>{translate("cardMigration")}</Typography>
            )}
        </div>
    );
};

export default MigratedCard;