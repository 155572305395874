import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchActivity, fetchPendingTransactions } from 'lib/actions';

const useTransactions = provider => {
    const dispatch = useDispatch();

    const fetchNextPage = useCallback(() => {
        dispatch(fetchActivity(false, 10, provider));
    }, [dispatch, provider]);

    useEffect(() => {
        dispatch(fetchActivity(true, 10, provider));
        dispatch(fetchPendingTransactions());
    }, [dispatch, provider]);

    return {
        fetchNextPage,
    };
};

export default useTransactions;
