import { useState, useEffect } from 'react';
import { sliceCarouselCards, changeDirection, shouldChange } from 'utils/cards';
import { addCardholder } from '../cards';

const useCardCarousel = cards => {
    const [carouselCards, setCarouselCards] = useState([]);
    const [currentCard, setCurrentCard] = useState({});
    const [currentCardIndex, setCurrentCardIndex] = useState(0);
    const [isStart, setIsStart] = useState(true);
    const [isEnd, setIsEnd] = useState(false);

    const handleDirection = clockwise => {
        setCurrentCardIndex(changeDirection(currentCardIndex, clockwise));
    };

    const handleCardClick = position => {
        if ((isStart && position === 'left') || (isEnd && position === 'right'))
            return;
        if (position === 'right') {
            setCurrentCardIndex(changeDirection(currentCardIndex, true));
        } else if (position === 'left') {
            setCurrentCardIndex(changeDirection(currentCardIndex, false));
        }
    };

    useEffect(() => {
        const updatedCards = addCardholder(cards);
        setCarouselCards(sliceCarouselCards(updatedCards, currentCardIndex));
        setCurrentCard(updatedCards[currentCardIndex]);
        setIsStart(shouldChange(updatedCards, currentCardIndex, false));
        setIsEnd(shouldChange(updatedCards, currentCardIndex, true));
        //eslint-disable-next-line
    }, [currentCardIndex, cards]);

    return {
        carouselCards,
        currentCard,
        currentCardIndex,
        handleDirection,
        handleCardClick,
        isStart,
        isEnd,
    };
};

export default useCardCarousel;
