import dayjs from 'dayjs';
import PendingTransactionCard from './PendingTransactionCard';
import AccountBalanceCard from './AccountBalanceCard';
import TransactionsCard from './TransactionsCard';
import { FormattedTransaction } from 'lib/type/types';

interface TransactionsProps {
    data: FormattedTransaction[];
}

const Transactions = ({ data }: TransactionsProps) => {
    const renderItem = (item, index) => {
        switch (item.type) {
            case 'sectionHeader':
                return <h2 key={index}>{item.text}</h2>;
            case 'accountBalance':
                return <AccountBalanceCard key={index} item={item} />;
            case 'pending':
                return <PendingTransactionCard key={index} item={item} />;
            default:
                return (
                    <TransactionsCard
                        key={index}
                        item={item}
                        keyIndex={`${dayjs(item.createdDate).format()}-${index}`}
                    />
                );
        }
    };

    return (
        <div className="monthly-transaction-container">
            {data.map((item, index) => renderItem(item, index))}
        </div>
    );
};

export default Transactions;
