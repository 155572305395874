import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useTranslate from 'lib/languages/useTranslate';
import useCardCarousel from 'utils/hooks/useCardCarousel';
import { Link } from 'react-router-dom';
import CarouselContainer from '../../carousel/containers/CarouselContainer';
import CarouselButtonsContainer from '../../carouselButtons/containers/CarouselButtonsContainer';
import {
    getAccountPostError,
    getCards,
    getFeesIsFetching,
} from 'lib/selectors';
import { showModal } from 'actions/modal';
import {
    getCardsIsFetching,
    getCardsIsPosting,
    getCardsPostSuccess,
} from 'lib/selectors';
import { usePrevious } from 'lib/utils';
import { CARD_FRAME, ENTER_CVC_MODAL } from 'constants/shared/modalTypes';
import LoadingComponent from 'components/shared/generic/loader/LoadingComponent';
import { useCheckAccountStatus } from 'lib/hooks';
import { dispatchHideModal } from 'utils/modals';
import { CardProvider } from 'lib/type/enums';
import { REACT_APP_VIEW_PIN_PHONE_NUMBER } from 'config';

const CardsCarouselContainer = ({ account }) => {
    const dispatch = useDispatch();
    const cards = useSelector(getCards);
    const isFetching = useSelector(getCardsIsFetching);
    const isPosting = useSelector(getCardsIsPosting);
    const postSuccess = useSelector(getCardsPostSuccess);
    const prevProps = usePrevious({ isPosting, postSuccess });
    const isPremiumPlus = account.productType.includes('PREMP');
    const translate = useTranslate('cards');
    const [isDormant] = useCheckAccountStatus();
    const feesIsFetching = useSelector(getFeesIsFetching);
    const error = useSelector(getAccountPostError);

    useEffect(() => {
        if (prevProps.isPosting && !isPosting && !postSuccess && !error) {
            dispatchHideModal(dispatch);
        }
    }, [prevProps, isPosting, postSuccess, error, dispatch]);
    const {
        carouselCards,
        currentCard,
        handleDirection,
        handleCardClick,
        isStart,
        isEnd,
    } = useCardCarousel(cards);

    const onNextClick = () => handleDirection(true);
    const onPrevClick = () => handleDirection(false);

    const handleEnterCVC = () => {
        const isCardProviderTribe = currentCard.provider === CardProvider.TRIBE;

        dispatch(
            showModal({
                type: isCardProviderTribe ? CARD_FRAME : ENTER_CVC_MODAL,
                props: {
                    cardSerial: currentCard.cardSerial,
                    phone: REACT_APP_VIEW_PIN_PHONE_NUMBER,
                },
            }),
        );
    };

    return (
        <>
            {isFetching || feesIsFetching ? (
                <LoadingComponent />
            ) : (
                <CarouselContainer
                    cards={carouselCards}
                    account={account}
                    handleCardClick={handleCardClick}
                />
            )}
            <CarouselButtonsContainer
                onNextClick={onNextClick}
                onPrevClick={onPrevClick}
                onViewPinClick={handleEnterCVC}
                start={isStart}
                end={isEnd}
                card={currentCard}
                isDormant={isDormant}
            />
            {!isDormant && !isPremiumPlus && (
                <Link
                    className="sm-button upgrade-account-button"
                    to="/more/manage-subscription"
                >
                    <i className="icon fa fa-level-up" />
                    <span>{translate('upgradeAccount')}</span>
                </Link>
            )}
        </>
    );
};

export default CardsCarouselContainer;
