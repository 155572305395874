import useTranslate from 'lib/languages/useTranslate';
import { Link } from 'react-router-dom';
import { formatMoney } from 'lib/utils';
import { Currency } from 'lib/type/enums';
import { Transaction } from 'lib/type/types';

interface PendingTransactionCardProps {
    item: Transaction;
}

const PendingTransactionCard = ({ item: { amount, count, value, currency = Currency.GBP } }: PendingTransactionCardProps) => {
    const translate = useTranslate('pendingTransactions');

    return (
        <div className="transaction-container">
            <Link to="/activity/pending">
                <div className="transaction-toggle">
                    <div className="transaction-left">
                        <i className="far fa-clock" />
                        <p className="transaction-name">
                            {translate('mainListText', { count })}
                        </p>
                    </div>
                    <div className="transaction-right">
                        <div className="transaction-item-container">
                            <p className="transaction-type"></p>
                        </div>
                        <div className="transaction-item-container">
                            <p
                                className={`transaction-amount ${
                                    value > 0 ? 'green' : ''
                                }`}
                            >
                                {`${formatMoney(amount)} ${currency}`}
                            </p>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default PendingTransactionCard;
