import { formatMoney } from 'lib/utils';
import { stringCaptialize } from 'utils/generic';
import { formatDate } from 'lib/utils/customDate';
import { Currency } from 'lib/type/enums';
import { Transaction } from 'lib/type/types';

interface TransactionCardProps {
    item: Transaction;
    keyIndex: string;
}

const TransactionCard = ({
    item: { createdDate, recipient, reference, value, currency = Currency.GBP },
    keyIndex,
}: TransactionCardProps) => {
    return (
        <div className="transaction-container">
            <input
                id={`collapsible-${keyIndex}`}
                className="toggle"
                type="checkbox"
            />
            <div className="transaction-toggle">
                <div className="transaction-left">
                    <p className="transaction-name">
                        {recipient
                            ? stringCaptialize(recipient)
                            : 'Transaction'}
                    </p>
                </div>
                <div className="transaction-right">
                    <div className="transaction-item-container">
                        <p className="transaction-type"></p>
                    </div>
                    <div className="transaction-item-container">
                        <p
                            className={`transaction-amount ${
                                value > 0 ? 'green' : ''
                            }`}
                        >
                            {`${value > 0 ? '+' : ''}${formatMoney(
                                value,
                            )} ${currency}`}
                        </p>
                    </div>
                </div>
            </div>
            <div className="collapsible-content">
                <div className="content-inner">
                    <p>
                        Date - <strong>{formatDate(createdDate)}</strong>
                    </p>
                    {!!reference && (
                        <p>
                            Reference - <strong>{reference}</strong>
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TransactionCard;
