import React from 'react';
import Button from 'components/shared/generic/button/presentational/Button';
import useTranslate from 'lib/languages/useTranslate';

const ModalOuter = ({
    children,
    handleClose,
    className,
    handleSubmit,
    isPosting,
    customButtons,
}) => {
    const translate = useTranslate('generic');

    return (
        <div className="modal-container size-lg-12">
            <div className={`modal-block ${className}`}>
                {children}

                <div
                    className={`button-container ${
                        customButtons ? 'no-display' : ''
                    }`}
                >
                    <Button className="cancel" onClick={handleClose}>
                        {translate('cancel')}
                    </Button>
                    <Button
                        type="submit"
                        onClick={handleSubmit}
                        isPosting={isPosting}
                        tracking
                        trackingLabel="MODAL_OPEN"
                    >
                        {translate('confirm')}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ModalOuter;
