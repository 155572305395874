import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CarouselButtons from '../presentational/CarouselButtons';
import { cardSatusValues } from 'lib/constants';
import { defrostCard, freezeCard } from 'lib/actions';
import { CardProvider } from 'lib/type/enums';
import { selectIsAccountMigrating } from 'lib/selectors';

const { BLOCKED, LOST, STOLEN, DAMAGED, ISSUED } = cardSatusValues;

const CarouselButtonsContainer = ({
    onPrevClick,
    onNextClick,
    onViewPinClick,
    start,
    end,
    card,
    isDormant,
}) => {
    const dispatch = useDispatch();
    const status = !!card && !!card.status && card.status;
    const isFrozen = status === BLOCKED;
    const isAccountMigrating = useSelector(selectIsAccountMigrating);
    const isTribeCardProvider = card?.provider === CardProvider.TRIBE;

    const isDisabledCard =
        isDormant ||
        [LOST, STOLEN, DAMAGED, ISSUED, 'N'].includes(status) ||
        (isTribeCardProvider && isAccountMigrating);

    const toggleFreeze = () => {
        if (isFrozen) dispatch(defrostCard(card.cardSerial));
        else dispatch(freezeCard(card.cardSerial));
    };

    return (
        <CarouselButtons
            onNextClick={onNextClick}
            onPrevClick={onPrevClick}
            onViewPinClick={onViewPinClick}
            toggleFreeze={toggleFreeze}
            start={start}
            end={end}
            isFrozen={isFrozen}
            isDisabledCard={isDisabledCard}
        />
    );
};

export default CarouselButtonsContainer;
